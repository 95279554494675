<template>
  <ProgressBarBase v-bind="($attrs, $props)">
    <template #title> <slot name="title" /> </template>
  </ProgressBarBase>
</template>

<script lang="ts">
import props from '~/components/ui/reactive/ProgressBarBase.vue';

export default {
  props,
};
</script>
